$(document).ready(function() {
  let filters = $('.filters-list .btn');
  let filter_all_products = $('.filters-list .filter-all-products');
  let product_filters = [];

  $('.filters-list .btn').on('click', function() {
    if ($(this).hasClass('filter-all-products')) {
      filters.each(function() {
        $(this).removeClass('btn-primary').addClass('btn-secondary');
      });
    } else {
      filter_all_products.removeClass('btn-primary').addClass('btn-secondary');
    }

    if ($(this).hasClass('btn-secondary')) {
        $(this).removeClass('btn-secondary').addClass('btn-primary');
    } else {
        $(this).removeClass('btn-primary').addClass('btn-secondary');
    }

    product_filters = [];

    $('.filters-list .btn-primary').each(function() {
      product_filters.push($(this).data('filter'));
    });

    $('.listing-product-wrapper').each(function() {
      let filters = $(this).data('filter').split(',');
      let matches = product_filters.some(filter => filters.includes(filter));

      if (matches) {
          $(this).show();
      } else {
          $(this).hide();
      }
    });
  });
});
