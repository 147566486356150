function setMarqueeContents() {
  $('.banner-markee-container').each(function() {
    const $container = $(this);
    const $text = $container.find('.banner-markee-text');
    const textWidth = $text.outerWidth();
    const windowWidth = $(window).innerWidth();
    const numberOfCopies = Math.ceil(windowWidth / textWidth) + 5;

    $container.find('.banner-markee-copies').html('');

    for (let i = 0; i < numberOfCopies; i++) {
      const copiesContainer = $container.find('.banner-markee-copies');
      $container.find('.banner-markee-content > .banner-markee-text').clone().appendTo(copiesContainer);
    }
  });
}

$(document).ready(function() {
  setMarqueeContents();

  $(window).resize(setMarqueeContents);
});

const updateScroll = () => {
  const markees = document.querySelectorAll(".banner-markee-content");

  markees.forEach(element => {
      let scroll = element.getBoundingClientRect().top;
      element.style.transform = "translateX(-" + scroll + "px)";
  });

  requestAnimationFrame(updateScroll);
};

requestAnimationFrame(updateScroll);

