(function ($) {
  var Search = {
    ajaxSearch : function(){
      $(document).on( 'submit', '#caraibos-search', function(e) {
        e.preventDefault();
          var $form = $(this),
              $input = $form.find('input[name="s"]'),
              query = $input.val(),
              $loader = $form.find('.search-loading'),
          defaultCaraibos = $('#default'),
          searchResults = $('#search-results'),
          results = searchResults.find('.results');

          if(query){
            $.ajax({
              type : 'post',
              url : '/wp-admin/admin-ajax.php',
              data : {
                action : 'load_search_results',
                query : query,
              },
              beforeSend: function() {
                $input.prop('disabled', true);
                $loader.show();
              },
              success : function( response ) {
                if(response.length){
                  defaultCaraibos.hide();
                  results.empty().html(response);
                  searchResults.fadeIn();
                }
                $input.prop('disabled', false);
                $loader.hide();
                results.scrollTop(0);
                $('#search-keyword').html(query);
              },
            });
          }
          return false;
        });
    },
    getRecentSearches : function(){
      var recentSearches = $('.recent-searches ul li a'),
      formSearch = $('#caraibos-search');

      recentSearches.each(function(){
        var _this = $(this);

        _this.click(function(e){
          e.preventDefault();
          var searchResult = _this.text();
          formSearch.find('.search-input').val(searchResult);
          setTimeout(function () {
            formSearch.submit();
          }, 800);
        });

      });
    },
  };

  Search.ajaxSearch();
  Search.getRecentSearches();

  $(document).on('click', '.search-button', function (e) {
    openSearch(e);
  });

  $(document).on('click', '#btn-search-close', function () {
    closeSearch();
  });

  function openSearch(e) {
    e.preventDefault();
    $('.search').removeClass('search-closed');
    checkNoScroll();
    $('#search-results').hide().find('.results').html('');
  }

  function closeSearch() {
    $('.search').addClass('search-closed');
    checkNoScroll();
    $('.search').find('.search-input').val('');
    $('#default').show();
    $('#search-results').hide().find('.results').html('');
  }

  function checkNoScroll() {
    if (!$('.search').hasClass('search-closed') || $('.mobile-nav').hasClass('mobile-active')) {
      $('body').addClass('no-scroll');
    } else {
      $('body').removeClass('no-scroll');
    }
  }
})(jQuery);

(function ($) {
  var Fn = {
    fixedHeader : function(){
      let body = $('body');
      $(window).scroll(function() {
        if( $(window).scrollTop() >= 1){
          body.addClass("fixed-header");
        } else{
          body.removeClass("fixed-header");
        }
      });
    },
    mobileMenu: function () {
      var mobileNav = $(".mobile-nav");

      $(".navbar-toggler").click(function (e) {
        e.preventDefault();
        $(this).toggleClass("active");
        let span = $(this).find(".navbar-toggler-text");
        if ($(this).hasClass("active")) {
          span.text("Fermer");
        } else {
          span.text("Menu");
        }
        mobileNav.toggleClass("mobile-active");
        checkNoScroll();
      });

      $("a.main-menu").click(function () {
        mobileNav.find(".sliding-menu").each(function () {
          $(this).removeClass("open moved-left");
        });
        checkNoScroll();
      });

      mobileNav
        .find(".nav-tree")
        .find("li")
        .each(function () {
          if ($(this).find("> .sliding-menu").length >= 1) {
            $(this).addClass("has-children");
          }
        });

      mobileNav
        .find(".nav-tree")
        .find(".has-children > a")
        .click(function () {
          var e = $(this),
            t = e.parent().parent().parent();
          e.siblings().addClass("open");
          if (t.hasClass("open")) {
            t.addClass("moved-left");
          } else {
            t.addClass("open");
          }
        });

      let backLinks = document.querySelectorAll(
        ".mobile-nav div.back-item > a.back"
      );

      backLinks.forEach(function (backLink) {
        backLink.addEventListener("click", function () {
          let e = this;
          let t = e.parentNode.parentNode;
          let n = e.parentNode.parentNode.parentNode.parentNode.parentNode;
          t.classList.remove("open");
          if (n.classList.contains("moved-left")) {
            n.classList.remove("moved-left");
          } else {
            n.classList.remove("open");
          }
          checkNoScroll();
        });
      });
    },
  };

  $(window).on("load", Fn.mobileMenu);
  $(window).on("load", Fn.fixedHeader);

  function checkNoScroll() {
    if (!$('.search').hasClass('search-closed') || $('.mobile-nav').hasClass('mobile-active')) {
      $('body').addClass('no-scroll');
    } else {
      $('body').removeClass('no-scroll');
    }
  }
})(jQuery);
