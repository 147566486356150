jQuery(document).ready(function($) {
    var page = 2;
    $("#load-more").on("click", function(e) {
        e.preventDefault();
        var button = $(this);
        var data = {
            action: "search_filter",
            page: page,
            search_filter_id: button.data("search_filter_id"),
        };
        $.ajax({
            url: button.data("url"),
            type: "POST",
            data: data,
            beforeSend: function() {
                button.text("Chargement...");
            },
            success: function(response) {
                if (response) {
                    $("#listing-cocktails-recipes-container").append($(response).find(".listing-single-cocktail-wrapper"));
                    page++;
                    button.text("Afficher plus de recettes");
                } else {
                    button.text("Plus de recettes disponibles");
                    button.prop("disabled", true);
                }
            },
        });
    });
});
